<template>
  <v-footer app class="blue-grey darken-4 justify-space-between">
    <span class="caption white--text text-capitalize"
      >{{ $t("powered by") }}
      <a class="orange--text" target="_blank" href="https://www.mijinitech.com"
        >MijiniTech</a
      ></span
    >

    <span class="caption white--text"
      >{{ $t("copyright") }} &copy; {{ currentYear }}</span
    >
  </v-footer>
</template>

<script>
import { format } from "date-fns";
export default {
  name: "Permis",
  data: function () {
    return {
      currentYear: format(new Date(), "yyyy"),
      drawer: false,
    };
  },
};
</script>
